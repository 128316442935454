 .slider-container {
     width: 100%;
     background-size: cover;
     background-position: center center;
     transition: background-image 1s ease-in-out;
     overflow: hidden;
     text-align: center;
 }

 .inner-container {
     text-align: center;
     padding-top: 10vh;
     padding-bottom: 10vh;
 }

 .slider {
     transition: transform 0.5s ease;
     opacity: 1;
     transition: opacity 0.5s ease;
 }

 .slider.left {
     transform: translateX(-100%);
     opacity: 0;
 }

 .slider.right {
     transform: translateX(100%);
     opacity: 0;
 }

 .salawat-content {
     background-color: #ffffff6b;
 }

 .dark-mode .salawat-content {
     background-color: #474444b6;
 }

 .post-title {
     font-size: 24px;
     margin-bottom: 10px;
     text-align: center;
 }

 .arabic {
     padding: 25px 0px;
 }

 .post-arabic {
     font-size: 36px;
     font-weight: 900;
 }

 .post-divider {
     margin: 20px auto;
     border-color: rgb(204, 204, 204);
 }

 .translation {
     padding: 25px 0px;
 }

 .translation-content {
     font-size: 25px;
 }

 .post-image {
     width: 100%;
     height: auto;
     max-height: 300px;
     object-fit: cover;
     margin-bottom: 20px;
     transition: opacity 0.5s ease-in-out;
 }

 button {
     padding: 10px 20px;
     font-size: 40px;
     border: none;
     border-radius: 5px;
     cursor: pointer;
 }

 button:disabled {
     background-color: #cccccc;
     cursor: not-allowed;
 }

 .prev,
 .next {
     position: absolute;
     top: 41%;
     transform: translateY(-50%);
     background-color: #333;
     color: white;
     border: none;
     padding: 10px;
     cursor: pointer;
 }

 .prev {
     left: 0px;
 }

 .next {
     right: 0px;
 }

 .prev:hover,
 .next:hover {
     background-color: #555;
 }
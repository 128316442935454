.btn-contact {
    color: rgb(255, 255, 255);
    background-color: #375543 !important;
}

.form-select {
    float: left;
    width: 100%;
    font-size: 15px;
    color: #333;
    border: 1px solid rgba(0, 0, 0, .3);
    padding: 10px 15px;
    height: 50px;
    margin-top: 20px;
    display: block;
    width: 100%;
    padding: .375rem 2.25rem .375rem .75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #52565b;
    background-color: #fff;
    background-image: url(data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e);
    background-repeat: no-repeat;
    background-position: right .75rem center;
    background-size: 16px 12px;
    border: 1px solid #ced4da;
    border-radius: 0px;
    appearance: none;
}
.salawat-list {
    display: flex;
    padding: 10px;
    background-color: #f5f5f5;
}

.dark-mode .salawat-list {
    background-color: #343a40;
}

.salawat-card {
    width: 100%;
    flex: 0 0 auto;
    margin: 10px;
    padding: 15px;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
}

.dark-mode .salawat-card {
    background-color: #333;
    box-shadow: 0 4px 8px rgba(255, 255, 255, 0.1);

}

.salawat-image {
    width: 100%;
    height: 150px;
    object-fit: cover;
    border-radius: 8px;
    margin-bottom: 10px;
}

.salawat-info {
    text-align: center;
}

.salawat-title {
    text-align: center,
}

.salawat-excerpt {
    font-size: 14px;
    color: #555;
}
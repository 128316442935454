body {
    margin: 0;
    padding: 0;
    font-family: Arial, sans-serif;
}

header {
    text-align: center;
}

.logo {
    max-width: 200px;
    /* Adjust the maximum width of the logo */
}

.menu-left,
.menu-right {
    display: inline-block;
    vertical-align: top;
    padding: 30px 0px;
}

.menu-left {
    float: left;
}

.menu-right {
    float: right;
}


.menu-container {
    text-align: center;
    margin: 50px auto;
}
.header-container {
    text-align: center;
    /* Center the header */
    margin: 50px auto;
    /* Add space around the container */
    padding: 20px;
    background-color: #282c34;
    /* Background color for the header container */
    border-radius: 10px;
    width: fit-content;
}

.header-3d {
    font-weight: bold;
    background: white;
    position: relative;
    text-transform: uppercase;
    letter-spacing: 2px;
    margin: 0;
}

.header-3d::before {
    content: attr(data-text);
    position: absolute;
    top: 0;
    left: 5px;
    width: 100%;
    height: 100%;
    color: #ff0000;
    /* 3D shadow color */
    z-index: -1;
    text-shadow: -3px 3px 0 #00ff00, -6px 6px 0 #0000ff, -9px 9px 0 #ff00ff;
}

.header-3d::after {
    content: attr(data-text);
    position: absolute;
    top: 0;
    left: 10px;
    width: 100%;
    height: 100%;
    color: #000000;
    /* 3D base shadow color */
    z-index: -2;
    text-shadow: -3px 3px 0 #ff0000, -6px 6px 0 #00ff00, -9px 9px 0 #0000ff;
}
.dark-mode {
  background-color: #121212;
}



/* Additional styles */
.dark-mode header {
  background-color: #1e1e1e;
}

.green-logo {
  display: block;
}

.dark-mode .green-logo {
  display: none;
}

.white-logo {
  display: none;
}

.dark-mode .white-logo {
  display: block;
}


.btn-mode {
  right: 0px;
  top: 0px;
  position: absolute;
}

.dark-mode .btn-mode {
  color: white;
}

button {
  background: none;
  border: none;
  cursor: pointer;
  font-size: 24px;
}

button:focus {
  outline: none;
}

button svg {
  color: currentColor;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}
.salawat-list-liner {
    display: flex;
    overflow-x: scroll;
    padding: 20px;
    background-Color: #f5f5f5;
}

.dark-mode .salawat-list-liner {
    background-color: #343a40;
}

.salawat-card-liner {
    flex: 0 0 auto;
    width: 300px;
    margin: 10px;
    padding: 15px;
    background-color: #fff;
    border-Radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
}

.dark-mode .salawat-card-liner {
    background-color: #333;
    box-shadow: 0 4px 8px rgba(255, 255, 255, 0.1);
}

.salawat-image-liner {
    width: 100%;
    height: 150px;
    object-fit: cover;
    border-radius: 8px;
    margin-bottom: 10px
}

.salawat-info-liner {
    text-align: center;
}

.salawat-title-liner {
    font-size: 18px;
    margin-bottom: 10px;
}

.dark-mode .salawat-title-liner {
    color: white;
}
.custom-dropdown {
    position: relative;
    width: 85px;
    margin: auto;
}

.dropdown-selected {
    padding: 8px;
    border: 1px solid #ccc;
    cursor: pointer;
    display: flex;
    align-items: center;
}

.dropdown-selected-content {
    display: block;
    align-items: center;
}

.dropdown-options {
    position: absolute;
    width: 100%;
    border: 1px solid #ccc;
    background-color: #fff;
    color: black;
    z-index: 10;
    max-height: 200px;
    overflow-y: auto;
}

.dropdown-option {
    padding: 8px;
    display: block;
    align-items: center;
    cursor: pointer;
}

.dropdown-option:hover {
    background-color: #f1f1f1;
}